<template>
  <div class="page-wrapper shifts__wrapper">
    <div class="container">
      <div class="shifts__inner page-inner">
        <div class="shifts__title-wrapper page-title-wrapper">
          <div class="shifts__title page-title">Shifts</div>
          <div @click="showAddShift" class="shifts__button button button--fill">Add New Shift</div>
        </div>
        <div class="shifts__table table">
          <div class="table__title">
            <div class="table__title-item">On/Off</div>
            <div class="table__title-item">Shift Name</div>
            <div class="table__title-item">Daily Goal</div>
            <div class="table__title-item">Weekly Goal</div>
            <div class="table__title-item">Monthly Goal</div>
          </div>
          <div class="table__main">
            <Shift v-for="shift in shifts" :key="shift.id" :info="shift" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Shift from '@/views/Admin/Shifts/Shift'

export default {
  name: 'Shifts',
  components: {Shift},
  data: () => ({
    shifts: [
      {
        id: 1,
        active: false,
        name: '🌙  Night Shift',
        dailyGoal: '$1000',
        weeklyGoal: '$7000',
        monthlyGoal: '$37000'
      },
      {
        id: 2,
        active: true,
        name: '🌙  Night Shift',
        dailyGoal: '$1000',
        weeklyGoal: '$7000',
        monthlyGoal: '$37000'
      },
      {
        id: 3,
        active: true,
        name: '🌙  Night Shift',
        dailyGoal: '$1000',
        weeklyGoal: '$7000',
        monthlyGoal: '$37000'
      },
      {
        id: 4,
        active: true,
        name: '🌙  Night Shift',
        dailyGoal: '$1000',
        weeklyGoal: '$7000',
        monthlyGoal: '$37000'
      },
      {
        id: 5,
        active: false,
        name: '🌙  Night Shift',
        dailyGoal: '$1000',
        weeklyGoal: '$7000',
        monthlyGoal: '$37000'
      },
      {
        id: 6,
        active: true,
        name: '🌙  Night Shift',
        dailyGoal: '$1000',
        weeklyGoal: '$7000',
        monthlyGoal: '$37000'
      },
      {
        id: 7,
        active: true,
        name: '🌙  Night Shift',
        dailyGoal: '$1000',
        weeklyGoal: '$7000',
        monthlyGoal: '$37000'
      },
      {
        id: 8,
        active: true,
        name: '🌙  Night Shift',
        dailyGoal: '$1000',
        weeklyGoal: '$7000',
        monthlyGoal: '$37000'
      },
      {
        id: 9,
        active: false,
        name: '🌙  Night Shift',
        dailyGoal: '$1000',
        weeklyGoal: '$7000',
        monthlyGoal: '$37000'
      },
      {
        id: 11,
        active: true,
        name: '🌙  Night Shift',
        dailyGoal: '$1000',
        weeklyGoal: '$7000',
        monthlyGoal: '$37000'
      },
      {
        id: 12,
        active: true,
        name: '🌙  Night Shift',
        dailyGoal: '$1000',
        weeklyGoal: '$7000',
        monthlyGoal: '$37000'
      },
      {
        id: 13,
        active: true,
        name: '🌙  Night Shift',
        dailyGoal: '$1000',
        weeklyGoal: '$7000',
        monthlyGoal: '$37000'
      },
      {
        id: 14,
        active: true,
        name: '🌙  Night Shift',
        dailyGoal: '$1000',
        weeklyGoal: '$7000',
        monthlyGoal: '$37000'
      },
      {
        id: 15,
        active: true,
        name: '🌙  Night Shift',
        dailyGoal: '$1000',
        weeklyGoal: '$7000',
        monthlyGoal: '$37000'
      }
    ]
  }),
  methods: {
    showAddShift() {
      this.$root.$emit('ShowSidebar', 'AddShift')
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>

<template>
  <div class="table__row" :class="{'table__row--dec': !info.active}">
    <div class="table__cell">
      <toggle-button color="#0290FC" :width="40" :sync="true" v-model="info.active" />
    </div>
    <div class="table__cell">
      <div class="table__cell-title">{{ info.name }}</div>
    </div>
    <div class="table__cell">
      <div class="table__cell-title">{{ info.dailyGoal }}</div>
    </div>
    <div class="table__cell">
      <div class="table__cell-title">{{ info.weeklyGoal }}</div>
    </div>
    <div class="table__cell">
      <div class="table__cell-title">{{ info.monthlyGoal }}</div>
    </div>
  </div>
</template>

<script>
import {ToggleButton} from 'vue-js-toggle-button'

export default {
  name: 'Shift',
  components: {
    ToggleButton
  },
  props: {
    info: {
      type: Object,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
